<template>
	<div class="sld_contract_main">
		<div class="container">
			<h3>{{ L['合同管理'] }}</h3>

			<div class="layer4 flex-col">
				<div class="bd5 flex-col">
					<div class="outer1 flex-row">
						<div class="bd6 flex-col">
							<span class="word23">{{ L['认证信息'] }}</span>
							<span class="word24 active" v-if="authentication.isCertified != null">
								{{
									authentication.userType == 1 ? L['个人: '] : authentication.userType == 2 ? L['企业: ']
									:
									L['商户: ']
								}}{{ authentication.userName }}
								<span class="word244 green" v-if="authentication.isCertified !== 0">{{L['已认证']}}</span>
								<span class="word244 red" v-else>{{ L['未认证'] }}</span>
							</span>
							<span class="word24" v-else>{{ L['未认证'] }}</span>
							<span v-if="!(authentication.isCertified != null) && (memberInfo.memberType == 1 || memberInfo.memberType == 3)"
								class="txt13" @click="gotoAuth">{{ L['去认证'] }}&gt;&gt;</span>
							<span v-else-if="authentication.isCertified != null && (memberInfo.memberType == 1 || memberInfo.memberType == 3)"
								class="txt13" @click="gotoAuth">{{ L['重新认证'] }}&gt;&gt;</span>
						</div>
						<template v-if="authentication.isCertified != null">
							<div class="layer2 flex-col"></div>
							<div class="layer3 flex-col">
								<span class="word28">{{ L['签名'] }}</span>
								<div class="section7 flex-col">
									<img :src="authentication.signImageUrl" />
								</div>
							</div>
						</template>
						<template v-else>
							<div class="bd7 flex-col"></div>
							<div class="bd8 flex-col">
								<span class="info15">{{ L['签名'] }}</span>
								<span class="txt14">{{ L['未设置'] }}</span>
							</div>
						</template>
					</div>
				</div>

				<div class="bd9 flex-row">
					<span class="word25">{{ L['合同号：'] }}</span>
					<div class="block7 flex-col">
						<el-input v-model="searchVal.contractCode" :placeholder="L['请输入合同号']" />
					</div>

					<span class="word27">{{ L['订单号：'] }}</span>
					<div class="block7 flex-col">
						<el-input v-model="searchVal.orderSn" :placeholder="L['请输入订单号']" />
					</div>

					<span class="word27">{{ L['状态：'] }}</span>
					<div class="block7 flex-col">
						<div class="layer6 flex-row">
							<el-select v-model="searchVal.state" :placeholder="L['请选择']">
								<el-option v-for="item in stateList" :key="item" :label="item.name"
									:value="item.value">
								</el-option>
							</el-select>
						</div>
					</div>
				</div>
				<div class="bd10 flex-row">
					<span class="info16">{{ L['采购单号：'] }}</span>
					<div class="block7 flex-col">
						<el-input v-model="searchVal.purchaseSn" :placeholder="L['请输入采购单号']" />
					</div>
					<span class="word27">{{ L['创建时间：'] }}</span>
					<div class="block7 flex-col">
						<div class="outer2 flex-row">
							<el-date-picker v-model="selectTime" type="daterange" :range-separator="L['至']"
								:start-placeholder="L['开始日期']" :end-placeholder="L['结束日期']" value-format="YYYY-MM-DD">
							</el-date-picker>
						</div>
					</div>

				</div>
				<div class="flex_row_between_center">
					<button class="bd15 flex-col" @click="addContract">
						<span class="word35">{{ L['合同申请'] }}</span>
					</button>
					<div class="bd14 flex-row">
						<button class="layer7 flex-col" @click="goSearch">
							<span class="info19">{{ L['搜'] }}&nbsp;{{ L['索'] }}</span>
						</button>
						<div class="layer8 flex-col" @click="initSearch">
							<span class="txt17">{{ L['重'] }}&nbsp;{{ L['置'] }}</span>
						</div>
					</div>
				</div>

				<div v-if="contractList.data.length > 0">
					<div class="box9_box"
						:style="{ 'overflow-x': contractList.data.length > 0 ? 'auto' : 'hidden' }">
						<div class="box9 flex-col">
							<div class="mod3 flex-row">
								<span class="txt18">{{ L['合同号'] }}</span>
								<span class="txt18 word38">{{ L['供应商'] }}</span>
								<span class="txt18 info22">{{ L['合同金额'] }}</span>

								<span class="txt18 word37">{{ L['包含订单'] }}</span>
								<span class="txt18 info21">{{ L['采购单号'] }}</span>
								<span class="txt18 word39">{{ L['创建时间'] }}</span>
								<span class="txt18 txt19">{{ L['合同形式'] }}</span>
								<span class="txt18 word40">{{ L['状态'] }}</span>
								<span class="txt18 txt20">{{ L['备注'] }}</span>
								<span class="txt18 info23">{{ L['操作'] }}</span>
							</div>
						</div>
						<template v-if="contractList.data.length > 0">
							<template v-for="(item, index) in contractList.data" :key="item.contractId">
								<div class="box10 flex-col">
									<div class="layer10 flex-row">
										<span class="word44">{{ item.contractCode }}</span>
										<span class="txt23">{{ item.storeName }}</span>
										<span class="txt23 word48">{{ L['¥'] }}{{ item.contractAmount }}</span>


										<div class="outer5 flex-col">
											<span v-for="(items, indexs) in item.contractOrderList"
												:key="indexs" class="info26"
												:class="{ isMore: item.isOrderMore }">
												<router-link
													:to="`/member/order/info?orderSn=${items.orderSn}`"
													v-if="items.orderSn">
													{{ items.orderSn }}</router-link>
												<span v-else>--</span>
											</span>

											<span class="txt281" @click="orderSnMore(item, 'isOrderMore')"
												v-show="item.contractOrderList.length > 3">{{
													item.isOrderMore ? L['收起'] : L['更多']
												}} <i
													:class="`el-icon-arrow-${item.isOrderMore ? 'up' : 'down'}`"></i></span>

											<span class="info26"
												v-show="!item.contractOrderList.length">--</span>

										</div>


										<div class="outer6 flex-col">
											<span v-for="(items, indexs) in item.contractOrderList"
												:key="indexs" class="info27"
												:class="{ isMore: item.isPurMore }"
												:title="items.purchaseSn">{{
													items.purchaseSn ?
													items.purchaseSn : '--'
												}}</span>


											<span class="txt281" @click="orderSnMore(item, 'isPurMore')"
												v-show="item.contractOrderList.length > 3">{{
													item.isPurMore ? L['收起'] : L['更多']
												}} <i
													:class="`el-icon-arrow-${item.isPurMore ? 'up' : 'down'}`"></i>
											</span>

											<span class="info27"
												v-show="!item.contractOrderList.length">--</span>


										</div>
										<span class="txt23 txt24">{{ item.createTime }}</span>
										<span class="txt23 word49">{{
											item.typeValue ? item.typeValue : '--'
										}}</span>
										<span class="txt23 info29">{{
											item.stateValue ? item.stateValue : '--'
										}}</span>
										<template v-if="item.memberRemark">
											<div class="box16 flex-col">
												<span class="info33" @click="open_edit(index)">{{
													item.memberRemark
												}}</span>

											</div>
										</template>
										<template v-else>
											<div class="outer7 flex-col">
												<i class="iconfont iconbianji icon11"
													@click="open_edit(index)"></i>
											</div>
										</template>

										<div class="outer8 flex-col">
											<span class="word50" v-if="item.type != 2 && item.state == 2"
												@click="changeState(item.contractId)">{{ L['转为正式'] }}</span>
											<span class="word50" @click="detail(item)">{{ L['查看'] }}</span>
											<span class="word50" v-if="item.state == 2 || item.state == 4"
												@click="review(item)">{{ L['下载'] }}</span>
											<span class="word50" v-if="item.state == 1 || item.state == 2"
												@click="drop(item.contractId)">{{ L['作废'] }}</span>
										</div>
									</div>
								</div>
							</template>
						</template>
					</div>
				</div>

				<div class="box9_box" v-else>
					<div class="bd13 flex-col">
						<div class="bd14 flex-col">
							<div class="block7 flex-col"></div>
							<span class="word42">{{ L['暂无申请记录'] }}</span>
						</div>
					</div>
				</div>


				<el-pagination @current-change="handleCurrentChange" :currentPage="pageData.current"
					:page-size="pageData.pageSize" layout="prev, pager, next, jumper" :total="pageData.total"
					:hide-on-single-page="false" class="flex_row_end_center" style="margin-top:10px;">
				</el-pagination>
			</div>
		</div>

		<!-- 编辑备注 start -->
		<el-dialog ref="editRemark" :title="L['编辑备注']" v-model="editDialog" width="480px" top="30vh">
			<div class="edit_main">
				<span>{{ L['备注：'] }}</span>
				<el-input v-model="remarkVal" maxlength="20" :placeholder="L['请输入备注']" show-word-limit />
			</div>
			<div class="edit_btn">
				<div class="edit_cancle" @click="close_edit">{{ L['取消'] }}</div>
				<div class="edit_submit" @click="submit_edit">{{ L['确定'] }}</div>
			</div>
		</el-dialog>
		<!-- 编辑备注 end -->

		<el-dialog v-model="contractDialog" :title="L['转为正式合同']" width="440px" top="30vh">
			<div>
				<div class="dailog_radio">
					<!-- el-contract-start -->
					<el-radio :disabled="authentication.isCertified != null ? false : true" v-model="contractState"
						label="1">{{ L['转为正式电子合同'] }}</el-radio>
					<!-- el-contract-end -->
					<span v-if="authentication.isCertified == null" class="dailog_radio_tips">{{
						L['因您尚未完成实名认证，目前仅支持申请纸质合同。'] }}</span>
					<el-radio v-model="contractState" label="2">{{ L['转为正式纸质合同'] }}</el-radio>
				</div>
				<div class="dailog_btn">
					<div class="dailog_cancle" @click="cancleDailog">{{ L['取消'] }}</div>
					<div class="dailog_submit" @click="submitDailog">{{ L['确定'] }}</div>
				</div>
			</div>
		</el-dialog>
		<el-dialog v-model="confirmDialog" :title="L['签署验证']" width="440px" top="28vh">
			<div>
				<div class="confirm_tips">{{ L['将调用您的数字证书进行签署，您正在安全签约环境中，请放心签署。'] }}</div>
				<div class="confirm_title">
					{{ L['即将向您'] }}
					{{
						authentication.userAccount.substring(0, 3) + '***' + authentication.userAccount.substring(7)
					}}
					{{ L['的手机号码发送确认签署验证码'] }}</div>
				<div class="confirm_title">{{ L['请尽快输入验证码完成签署'] }}</div>
				<div class="confirm_info flex-row">
					<input v-model="confirmCode" @input="inputCode" placeholder="L['请输入手机验证码']" maxlength="6" />
					<div @click="confirmSend">{{ confirmTime ? confirmTime + L['s后重新发送'] : L['获取验证码'] }}</div>
				</div>
				<div v-if="confirmError" class="confirm_error">{{ confirmError }}</div>
				<div class="dailog_btn">
					<div class="dailog_cancle" @click="cancleConfirm">{{ L['取消'] }}</div>
					<div class="dailog_submit" @click="submitConfirm">{{ L['确定'] }}</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import { reactive, ref, getCurrentInstance, onMounted } from "vue";
import MemberTitle from "@/components/MemberTitle";
import { useRouter } from "vue-router";
import { useStore } from 'vuex';
import { ElMessage, ElMessageBox } from 'element-plus';
export default {
	name: "FinaceContract",
	components: {
		MemberTitle,
	},
	setup() {
		const { proxy } = getCurrentInstance();
		const L = proxy.$getCurLanguage();
		const router = useRouter();
		const store = useStore();
		const memberInfo = ref(store.state.memberInfo)
		const searchVal = reactive({ data: [] });
		const selectTime = ref("");
		const stateList = ref([
			{ id: 0, name: L['全部'], value: '' },
			{ id: 1, name: L['待供方处理'], value: '1' },
			{ id: 2, name: L['待选择合同类型'], value: '2' },
			{ id: 3, name: L['正式'], value: '4' },
			{ id: 3, name: L['废弃'], value: '5' }
		]);
		const pageData = reactive({
			current: 1,
			pageSize: 10,
			total: 0
		});
		const contractList = reactive({ data: [] });
		const contractDialog = ref(false);
		const contractId = ref(''); //编辑的合同id
		const contractState = ref('1');
		const editDialog = ref(false); //编辑申请数量弹窗
		const editIndex = ref('');
		const remarkVal = ref('');
		const authentication = ref({}); //认证信息
		const confirmDialog = ref(false);
		const confirmCode = ref(''); //签署验证验证码
		const confirmTime = ref('');
		const submitting = ref(false);
		const confirmError = ref('');
		let confirmTimeout = null;

		const goSearch = () => {
			searchVal.startTime = selectTime.value ? selectTime.value[0] : '';
			searchVal.endTime = selectTime.value ? selectTime.value[1] : '';
			getList();
		};

		const initSearch = () => {
			searchVal.contractCode = "";
			searchVal.orderSn = "";
			searchVal.purchaseSn = "";
			searchVal.startTime = "";
			searchVal.endTime = "";
			searchVal.state = ""; //合同状态，1-草稿，2-正式，3-作废，4-删除,默认全部
			selectTime.value = "";
			// searchVal.orderSn = '';
			getList();
		};

		const getList = () => {
			let param = {
				current: pageData.current,
				pageSize: pageData.pageSize,
			}
			if (searchVal.state) {
				param.state = searchVal.state;
			}
			if (searchVal.startTime) {
				param.startTime = searchVal.startTime + ' 00:00:00';
			}
			if (searchVal.endTime) {
				param.endTime = searchVal.endTime + ' 23:59:59';
			}
			if (searchVal.contractCode) {
				param.contractCode = searchVal.contractCode;
			}
			if (searchVal.orderSn) {
				param.orderSn = searchVal.orderSn;
			}

			if (searchVal.purchaseSn) {
				param.purchaseSn = searchVal.purchaseSn;
			}

			//获取合同列表
			proxy.$get("v3/business/front/contract/list", param)
				.then((res) => {
					if (res.state == 200) {
						contractList.data = res.data.list
						pageData.total = res.data.pagination.total;
					} else {
						ElMessage(res.msg);
					}
				});
		};

		const getAuth = () => { //获取认证信息
			proxy.$get("v3/business/front/contract/certificate/detail").then((res) => {
				if (res.state == 200) {
					authentication.value = res.data;
				} else {
					ElMessage.error('获取认证信息失败');
				}
			})
		};

		const gotoAuth = () => { //去认证
			router.push('/member/contract/authen');
		};

		const detail = (item) => {
			let ids = [];
			item.productInfoList.forEach(items => {
				ids.push(items.productId)
			})
			router.push({
				path: `/member/contract/detail`,
				query: {
					id: item.contractId,
					ids: ids.join()
				},
			});
		};

		const open_edit = (index) => { //编辑备注
			editDialog.value = true;
			editIndex.value = index;
			remarkVal.value = contractList.data[index].memberRemark ?
				contractList.data[index].memberRemark : ''
		};
		const close_edit = () => {
			editDialog.value = false;
			editIndex.value = '';
			remarkVal.value = '';
		};
		const submit_edit = () => {
			if (!remarkVal.value) {
				ElMessage.warning(L['请输入备注信息'])
				return;
			}
			let param = {
				contractId: contractList.data[editIndex.value].contractId,
				memberRemark: remarkVal.value
			}
			proxy
				.$post('v3/business/front/contract/update', param)
				.then(res => {
					if (res.state == 200) {
						ElMessage.success(res.msg);
						contractList.data[editIndex.value].memberRemark = remarkVal.value
						close_edit();
					} else {
						ElMessage.error(res.msg);
					}
				})
		};

		const drop = (id) => { //作废合同
			ElMessageBox.confirm(L['确定作废该合同?'], L['提示'], {
				confirmButtonText: L['确定'],
				cancelButtonText: L['取消'],
				type: 'warning',
			}).then(() => {
				proxy.$post("v3/business/front/contract/changeCancel", {
					contractId: id
				}).then(res => {
					if (res.state == 200) {
						ElMessage.success(res.msg)
						initSearch();
					} else {
						ElMessage(res.msg);
					}
				})
			})
		};

		const addContract = () => {
			router.push(`/member/contract/add`);
		};

		const changeState = (id) => {
			contractDialog.value = true;
			contractId.value = id;
			if (authentication.value.isCertified != null) {
				contractState.value = '1';
			} else {
				contractState.value = '2';
			}
		};

		const cancleDailog = () => {
			contractDialog.value = false;
			contractId.value = '';
			contractState.value = '1';
		};
		const submitDailog = () => {
			if (!contractState.value) {
				ElMessage.warning(L['请选择合同类型']);
			} else if (!submitting.value) {
				submitting.value = true;
				let param = {
					contractId: contractId.value,
					type: contractState.value
				}
				proxy
					.$post('v3/business/front/contract/changeState', param)
					.then(res => {
						if (res.state == 200) {
							contractDialog.value = false;
							if (contractState.value == 1) {
								confirmDialog.value = true;
							} else {
								ElMessage.success(res.msg);
								getList();
							}
						} else {
							ElMessage.error(res.msg);
						}
						submitting.value = false;
					})
			}
		};

		const confirmSend = () => {
			if (!authentication.value.userAccount) {
				ElMessage.warning(L['验证手机号获取错误'])
			} else if (confirmTimeout) {
				return;
			} else {
				let param = {
					contractId: contractId.value
				}
				confirmTime.value = 60;
				confirmTimeout = setInterval(() => {
					if (confirmTime.value) {
						confirmTime.value -= 1;
					} else {
						clearInterval(confirmTimeout)
						confirmTimeout = null;
						confirmTime.value = '';
					}
				}, 1000)
				proxy
					.$post('v3/business/front/contract/sendSignVCode', param)
					.then(res => {
						if (res.state == 200) {
							// console.log(res)
						} else {
							ElMessage.error(res.msg);
						}
					})
			}
		};
		const cancleConfirm = () => {
			confirmDialog.value = false;
			contractId.value = '';
			confirmCode.value = '';
			if (confirmTimeout) {
				clearInterval(confirmTimeout)
				confirmTimeout = null;
				confirmTime.value = '';
			}
			getList();
		};
		const submitConfirm = () => {
			if (submitting.value) {
				return;
			} else if (!confirmCode.value.trim()) {
				// ElMessage.info('请输入手机验证码');
				confirmError.value = L['请输入手机验证码'];
				confirmCode.value = '';
				return;
			}
			submitting.value = true;
			let param = {
				contractId: contractId.value,
				vcode: confirmCode.value
			}
			proxy
				.$post('v3/business/front/contract/signCert', param)
				.then(res => {
					if (res.state == 200) {
						confirmDialog.value = false;
						submitting.value = false;
						contractId.value = '';
						confirmCode.value = '';
						if (confirmTimeout) {
							clearInterval(confirmTimeout)
							confirmTimeout = null;
							confirmTime.value = '';
						}
						getList();
					} else {
						confirmError.value = res.msg;
						// ElMessage.error(res.msg);
						setTimeout(() => {
							submitting.value = false;
						}, 500)
					}
				})
		};

		//页数改变
		const handleCurrentChange = e => {
			pageData.current = Math.floor(e);
			getList();
		};

		//合同下载
		const review = (item) => {
			if (item.contractUrl) {
				window.open(item.contractUrl);
			} else {
				detail(item);
			}
		};

		const inputCode = () => {
			if (confirmError.value) {
				confirmError.value = '';
			}
		};

		const orderSnMore = (item, type) => {
			item[type] = !item[type]
		}

		onMounted(() => {
			getAuth();
			initSearch();
		});

		return {
			L,
			orderSnMore,
			searchVal,
			selectTime,
			stateList,
			pageData,
			contractList,
			contractDialog,
			contractId,
			contractState,
			goSearch,
			initSearch,
			changeState,
			detail,
			drop,
			addContract,
			cancleDailog,
			submitDailog,
			authentication,
			getAuth,
			gotoAuth,
			editDialog,
			editIndex,
			remarkVal,
			open_edit,
			close_edit,
			submit_edit,
			handleCurrentChange,
			memberInfo,
			confirmSend,
			cancleConfirm,
			submitConfirm,
			confirmDialog,
			confirmCode,
			confirmTime,
			submitting,
			review,
			confirmError,
			inputCode
		};
	},
};
</script>

<style lang="scss" scoped>
@import "@/style/contract/index.scss";
</style>
<style lang="scss">
.sld_contract_main .layer4 .block7 .el-input__inner {
	width: 222px;
	height: 32px;
	position: absolute;
	left: 0;
	top: 0;
}

.sld_contract_main .layer4 .block7 .el-select {
	position: absolute;
	left: 0;
	top: 0;
}

.sld_contract_main .layer4 .block7 .el-input--suffix .el-input__inner {
	width: 222px;
	height: 32px;
}

.sld_contract_main .layer4 .block7 .el-select .el-input {
	width: 222px;
	height: 28px;
}

.sld_contract_main .layer4 .block7 .el-input__suffix {
	top: -3px;
}

.sld_contract_main .layer4 .block7 .el-range-editor.el-input__inner {
	width: 222px;
	height: 32px;
}

.sld_contract_main .layer4 .block7 .el-date-editor .el-range-separator {
	width: 26px;
	line-height: 24px;
}

.sld_contract_main .layer4 .block7 .el-range-editor .el-range-input {
	font-size: 12px;
}

.sld_contract_main {
	.el-dialog__body {
		padding-top: 10px;
	}

	.dailog_radio {
		display: flex;
		align-items: center;
		flex-direction: column;

		.dailog_radio_tips {
			position: relative;
			bottom: 8px;
			color: #C4C7CF;
			margin-bottom: 8px;
		}

		.el-radio {
			margin: 0 10px 20px 0;
		}
	}

	.confirm_tips {
		height: 32px;
		line-height: 30px;
		color: #333333;
		font-size: 12px;
		font-family: Source Han Sans CN-Normal, Source Han Sans CN;
		font-weight: 400;
		background: #FFFAEF;
		padding-left: 10px;
		border: 1px solid #EAE3CB;
		border-radius: 4px;
		margin-bottom: 20px;
	}

	.confirm_title {
		line-height: 26px;
		color: #666666;
		font-size: 14px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		text-align: center;
	}

	.confirm_info {
		align-content: center;
		justify-content: center;
		margin-top: 25px;
		// margin-bottom: 25px;
		padding-bottom: 30px;

		// border-bottom: 1px solid $colorJ;
		input {
			width: 250px;
			height: 34px;
			padding: 0 8px;
			font-size: 13px;
			background: #FFFFFF;
			border-radius: 2px;
			border: 1px solid $colorH;
		}

		div {
			width: 92px;
			line-height: 34px;
			color: #666666;
			font-size: 12px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			text-align: center;
			cursor: pointer;
		}
	}

	.confirm_error {
		position: relative;
		bottom: 15px;
		left: 30px;
		color: $colorMain2;
	}

	.dailog_btn {
		display: flex;
		align-items: center;
		justify-content: center;
		border-top: 1px solid $colorJ;
		// margin-top: 20px;
		padding-top: 25px;
	}

	.dailog_cancle {
		width: 90px;
		height: 30px;
		line-height: 30px;
		text-align: center;
		border-radius: 2px;
		border: 1px solid $colorI;
		margin-right: 20px;
		cursor: pointer;
	}

	.dailog_submit {
		width: 90px;
		height: 30px;
		line-height: 30px;
		text-align: center;
		border-radius: 2px;
		color: #FFFFFF;
		background-color: $colorMain;
		border: 1px solid $colorMain;
		cursor: pointer;
	}

	.edit_main {
		.el-input {
			width: 360px;
		}

		.el-input__inner {
			width: 360px;
		}
	}

	.el-pagination {
		padding-top: 15px;
	}
}

.is-right {
	.el-date-range-picker__header {
		.el-icon-arrow-right {
			margin: 8px 0 0;
		}
	}
}

.txt281 {
	display: block;
	height: 12px;
	line-height: 12px;
	color: rgba(104, 104, 104, 1);
	font-size: 12px;
	font-family: SourceHanSansCN-Regular;
	text-align: center;
	margin-top: 10px;
	cursor: pointer;

	&:hover {
		color: $colorMain;
		text-decoration: underline;
	}
}
</style>